<template>
    <div class="preview_area show_sidebar" v-if="modelValue" :class="{ active : modelValue }">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <ul class="tabs_btn">
                <li :class="tab === 'bounces' ? 'active' : ''" @click="tab = 'bounces'">Bounces</li>
                <li :class="tab === 'complaints' ? 'active' : ''" @click="tab = 'complaints'">Complaints</li>
                <li :class="tab === 'unsubscribes' ? 'active' : ''" @click="tab = 'unsubscribes'">Unsubscribes</li>
            </ul>
            <div class="tabs_content">
                <button type="button" class="toggle_bar" @click="toggleSectionbar"><img src="@/assets/images/bar-left.svg" alt=""></button>
                <div class="viewpt_wpr">
                    <div class="content_area">
                        <div class="result_wpr new">
                            <div class="actions">
                                <ul>
                                    <li>
                                        <label for="check" class="checkbox">
                                            <input type="checkbox" id="check" hidden v-model="selectAll" @click="toggleAll">
                                            <span><i class="fas fa-check"></i></span>
                                            <h5>{{ selectedContacts.length ? `${selectedContacts.length} Selected` : `Select All ${params.per_page}`}}</h5>
                                        </label>
                                    </li>
                                    <li class="optionDrops contacts_tabs" @click="bulkAction = !bulkAction" v-if="selectedContacts.length" v-click-outside="closeBulkActionDropdown">
                                        Bulk Action<i class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="bulkAction ? 'active' : ''">
                                            <ul>
                                                <li @click="triggerBulkAction('sendSMSToggle')">Send SMS</li>
                                                <li @click="triggerBulkAction('addTagsToggle')">Add Tags</li>
                                                <li @click="triggerBulkAction('removeTagsToggle')">Remove Tags</li>
                                                <li @click="triggerBulkAction('statusToggle')">Switch Tabs</li>
                                                <li @click="triggerBulkAction('deleteContactsPrompt')">Delete Contact</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="optionDrops search_area ml-auto" :class="{'active': searchField}">
                                        <input type="text" placeholder="Search" :disabled="suppressedContactLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                                        <button class="search_btn" @click="searchField = !searchField;">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </li>
                                    <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                                        Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                        <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="suppression-per-page-filter" />
                                    </li>
                                    <!-- <li class="list_info">
                                        {{ contacts.from ? contacts.from : 0 }} - {{ contacts.to ? contacts.to : 0 }} of <span>{{ contacts.total }}</span>
                                    </li> -->
                                </ul>
                            </div>
                            <div v-if="suppressedContactLoader"><line-loader /></div>
                            <div class="scroll_table">
                                <table class="show_header">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Name</th>
                                            <th>Joined</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="contacts.total || suppressedContactLoader">
                                        <tr v-for="(contact, c) of contacts.data" :key="c">
                                            <td>
                                                <label :for="`contact-${contact.id}`" class="checkbox">
                                                    <input type="checkbox" :id="`contact-${contact.id}`" :value="contact.id" v-model="selectedContacts" hidden>
                                                    <span><i class="fas fa-check"></i></span>
                                                </label>
                                            </td>
                                            <td>
                                                <div class="user_wpr" v-click-outside="closeTemperatureOption">
                                                    <div class="user_img">
                                                        <img :src="contact.photo" alt="" @click.self="toggleProfile(contact)">
                                                        <span :class="`temp ${contact.temperature ? contact.temperature.toLowerCase() : 'warm'}`" @click="temperatureOption($event)">
                                                            <ul class="row_option">
                                                                <li @click="changeTemperature(contact.id, 'cold')">Cold</li>
                                                                <li @click="changeTemperature(contact.id, 'warm')">Warm</li>
                                                                <li @click="changeTemperature(contact.id, 'hot')">Hot</li>
                                                            </ul>
                                                        </span>
                                                        <svg class="tag" :class="{ active: contact.starred }" @click="toggleStarred($event, contact.id)" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.47 18.8"><path class="cls-1" d="M13.09,6.25l-.43-1.09c-.35-.88-.79-2-1.17-3a1.64,1.64,0,0,0-3,0c-.38,1-.82,2.1-1.17,3L6.85,6.25s-3.36.29-3.95.37C1.25,6.84,1.09,7.8,1.09,7.8a1.58,1.58,0,0,0,.46,1.6h0l2.47,2,.9.74-.29,1.13c-.24.92-.54,2.1-.8,3.1a1.64,1.64,0,0,0,.62,1.73h0a1.63,1.63,0,0,0,1.83.06h0L9,16.47l1-.63,1,.63,2.7,1.72a1.63,1.63,0,0,0,1.84-.05h0a1.64,1.64,0,0,0,.62-1.73c-.26-1-.56-2.18-.8-3.1L15,12.18l.91-.74,2.47-2h0a1.63,1.63,0,0,0-.94-2.88h0l-3.2-.2Z" transform="translate(-0.26 -0.4)"/></svg>
                                                    </div>
                                                    <h4 @click.self="toggleProfile(contact)">{{ contact.name }}</h4>
                                                </div>
                                            </td>
                                            <td>{{ moment(contact.date_created).format('ll') }}</td>
                                            <td>
                                                <div class="email_wpr">
                                                    <span class="email_add">{{ contact.email }}</span>
                                                    <div class="btn_wpr" v-if="contact.valid_email == 0">
                                                        <button class="unconfirm">Invalid Email</button>
                                                    </div>
                                                    <div class="btn_wpr" v-if="contact.is_confirm == 0">
                                                        <button class="unconfirm">Unconfirmed</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="email_wpr">
                                                    <span class="email_add">{{ contact.number }}</span>
                                                    <div class="btn_wpr" v-if="contact.number && contact.valid_sms == 0">
                                                        <button class="unconfirm">Invalid Phone</button>
                                                    </div>
                                                    <div class="btn_wpr" v-else-if="!contact.number">
                                                        <button class="unconfirm">Missing Phone</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{ contact.status_title }}</td>
                                            <td>
                                                <button type="button" v-if="contact.number" @click="selectedContacts = []; selectedContacts.push(contact.id); triggerBulkAction('sendSMSToggle');"><i class="fa fa-comment"></i></button>
                                                <button type="button" class="text-danger" @click="selectedContacts = []; selectedContacts.push(contact.id); triggerBulkAction('deleteContactsPrompt');"><i class="fa fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="7" class="px-4 text-center">
                                                <div class="empty_box">
                                                    <img src="@/assets/images/empty_state.svg">
                                                    <h4>No Records Found</h4>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="table_footer">
                            <ul>
                                <li>{{ contacts.from ? contacts.from : 0 }} - {{ contacts.to ? contacts.to : 0 }} of {{ contacts.total }}</li>
                            </ul>
                        </div>
                        <div class="pagination" v-show="contacts.total">
                            <pagination v-model="params.page" :range-size="0" :pages="contacts.last_page" @update:modelValue="handlePagination" />
                        </div>
                    </div>
                    <!-- <div class="action_wpr">
                        <button type="button" class="btn cancel_btn" @click="closeModal()">Exit</button>
                    </div> -->
                </div>
            </div>
        </div>
        <profile-component v-model="profile" :contact="selectedContact" :updateSelectedContact="updateSelectedContact" />
        <bulk-action-switch-tab v-model="statusToggle" :contacts="selectedContacts" />
        <bulk-action-sms v-model="sendSMSToggle" :contacts="selectedContacts" :default-sms="defaultSms" />
        <bulk-action-add-tag v-model="addTagsToggle" :contacts="selectedContacts" />
        <bulk-action-remove-tag v-model="removeTagsToggle" :contacts="selectedContacts" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapMutations } from 'vuex'

    const BulkActionSwitchTab = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/SwitchTab'))
    const BulkActionSms = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendSms'))
    const BulkActionAddTag = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/AddTag'))
    const BulkActionRemoveTag = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RemoveTag'))
    const ProfileComponent = defineAsyncComponent(() => import('@/pages/contact/components/Profile'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import moment from 'moment'

    export default {
        name: 'Suppression List',

        data () {
            return {
                tab: 'bounces',
                bulkAction: false,
                searchField: false,
                params: {
                    per_page: 5,
                    search: '',
                    page: 1,
                    type: 'bounces'
                },
                selectedContact: {},
                selectedContacts: [],
                statusToggle: false,
                sendSMSToggle: false,
                addTagsToggle : false,
                removeTagsToggle : false,
                selectAll : false,
                profile : false,
                isTyping : false,
                moment,
                defaultSms: `Hi {{first_name}}! It's {{owner_first_name}} from {{company_name}}. It looks like you unsubscribed from our emails. You can go here to re subscribe to our emails {{resubscribe_url}}. If you wish to remain unsubscribed just ignore this message.`,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            BulkActionSwitchTab,
            BulkActionSms,
            BulkActionAddTag,
            BulkActionRemoveTag,
            ProfileComponent,
        },

        watch: {
            tab (type) {
                const vm = this;

                vm.params.type    = type;
                vm.params.page    = 1;
                vm.params.search  = '';
                vm.selectedContacts = [];
                vm.selectAll = false;

                setTimeout(function () {
                    vm.getSuppressedContact(vm.params);
                }, 500);

            },

            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.selectAll = false;
                            vm.selectedContacts = [];
                            vm.getSuppressedContact(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.selectAll = false;
                            vm.selectedContacts = [];
                            vm.getSuppressedContact(vm.params);
                        }
                    }
                }
            },

            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.selectAll = false;
                vm.selectedContacts = [];
                vm.getSuppressedContact(vm.params);
            },

            contacts (contacts) {
                const vm = this;

                if (vm.selectedContact.id) {
                    const selected = contacts.data.find(contact => contact.id == vm.selectedContact.id);

                    vm.selectedContact = selected ? selected : {};
                }
            },

            profile (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            bulkActionWatcher () {
                const vm = this;

                vm.selectAll = false;
                vm.selectedContacts = [];
                vm.getSuppressedContact(vm.params);
            },

            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.resetParams();

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },


        computed: mapState({
            contacts: state => state.contactModule.suppressedContacts,
            bulkActionWatcher: state => state.contactModule.bulkActionWatcher,
            suppressedContactLoader: state => state.contactModule.suppressedContactLoader,
        }),

        methods: {
            ...mapActions({
                bulkDelete: 'contactModule/bulkDelete',
                markContactAsStarred: 'contactModule/markContactAsStarred',
                getSuppressedContact: 'contactModule/getSuppressedContact',
                updateTemperature: 'contactModule/updateTemperature',
            }),

            ...mapMutations({
                resetTimezoneByCountry: 'commonModule/SET_TIMEZONES_BY_COUNTRY',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            toggleAll () {
                const vm = this;

                if (!vm.selectAll) {
                    vm.selectedContacts = vm.filterIndexValue(vm.contacts.data);
                } else {
                    vm.selectedContacts = [];
                }
            },

            filterIndexValue (object, index = 'id') {
                const records = [];

                object.forEach((obj) => { records.push(obj[index]) });

                return records;
            },

            toggleProfile (contact) {
                const vm = this;

                vm.resetTimezoneByCountry([]);
                vm.selectedContact = contact;
                vm.profile = true;
            },

            updateSelectedContact (contact) {
                const vm = this;

                vm.getSuppressedContact(vm.params);
                vm.selectedContact = contact;
            },

            toggleStarred (e, contact_id) {
                e.stopPropagation();

                const vm    = this;
                let element = e.currentTarget;
                let starred = 1;

                if (element.classList.contains('active')) {
                    element.classList.remove('active');
                    starred = 0;
                } else {
                    element.classList.add('active');
                    starred = 1;
                }

                vm.markContactAsStarred({ starred, contact_id }).then((result) => {
                    if (!result) {
                        if (starred == 1) {
                            element.classList.remove('active');
                        } else {
                            element.classList.add('active');
                        }
                    }
                });
            },

            triggerBulkAction (action) {
                const vm = this;
                vm.confirmationEmail = false;

                if (vm.selectedContacts.length) {
                    if (action == 'deleteContactsPrompt') {
                        const option = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover these contacts', 'Delete')
                              option.preConfirm = function() { return vm.bulkDelete({ contacts: vm.selectedContacts }); };

                        Swal.fire(option);
                    } else {
                        vm[action] = true;
                    }
                } else {
                    const options = Helper.swalWarningOptions('Oops!', 'You must select at least 1 contact to use Bulk Actions.');

                    Swal.fire(options)
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.selectedContacts = [];
                vm.selectAll = false;
                vm.getSuppressedContact(vm.params);
            },

            closeTemperatureOption () {
                let allEl = document.querySelectorAll('table .row_option');

                for(let i = 0; i < allEl.length; i++){
                    allEl[i].classList.remove('active');
                }
            },

            changeTemperature (contact_id, temperature) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are You Sure?', `You want to change temperature to ${temperature}.`)
                option.preConfirm = function() { return vm.updateTemperature({ contact_id, temperature}) };

                Swal.fire(option);
            },

            togglePerPageFilter () {
                const vm = this;
                vm.bulkAction = false;

                const filter = vm.$refs['suppression-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            resetParams () {
                const vm = this;

                vm.selectedContact  = {};
                vm.selectedContacts = [];
                vm.statusToggle     = false;
                vm.sendSMSToggle    = false;
                vm.addTagsToggle    = false;
                vm.removeTagsToggle = false;
                vm.selectAll        = false;
                vm.profile          = false;
                vm.isTyping         = false;
                vm.tab              = 'bounces';
                vm.bulkAction       = false;
                vm.params           = { per_page: 5, search: '', page: 1, type: 'bounces' };

                vm.getSuppressedContact(vm.params);
            },

            closeBulkActionDropdown () {
                const vm = this;

                vm.bulkAction = false;
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['suppression-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },
            toggleSectionbar(){
                const ls_bar = document.querySelector('.global_setting');
                if(ls_bar.classList.contains('show_m_sidebar')){
                    ls_bar.classList.remove('show_m_sidebar');
                }else{
                    ls_bar.classList.add('show_m_sidebar');
                }
            },
        },
    }
</script>

<style scoped>
    .viewpt_wpr{
        max-width: 1260px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        height: inherit;
    }
    .viewpt_wpr .action_wpr{
        padding: 15px 50px;
        position: sticky;
        bottom: 0;
        right: 0;
        left: 0;
        background: #fafafb;
        border-top: 1px solid #eaeaea;
        z-index: 3;
        margin: auto 0 0 0;
        width: auto;
    }
    .result_wpr.new .actions > ul{
        width: 100%;
    }
    .result_wpr table{
        min-width: 890px !important;
    }
    .result_wpr table th, .result_wpr table td{
        text-align: center;
    }
    .result_wpr table th:nth-child(2){
        text-align: left;
    }
    @media(max-width: 1199px){
        .global_setting .tabs_btn{
            z-index: 11;
        }
        .viewpt_wpr .action_wpr{
            padding: 15px 40px;
        }
    }
    @media(max-width: 767px){
        .result_wpr.new .actions > ul li.search_area, .result_wpr.new .actions > ul li.search_area.active{
            flex: 1 1 auto;
            order: -1;
        }
        .result_wpr.new .actions > ul li.sort_list{
            margin-left: auto;
            order: -1;
        }
        .result_wpr.new .actions > ul li:first-child{
            width: 50%;
            padding: 10px 5px;
        }
        .result_wpr.new .actions > ul li:nth-child(2){
            margin-left: auto;
        }
    }
    @media(max-width: 450px){
        .result_wpr.new .actions > ul li.search_area{
            width: auto;
        }
        .viewpt_wpr .action_wpr{
            padding: 15px 30px;
        }
    }
</style>
